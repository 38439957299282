<template>
  <div class="permissions-row">
    <div class="permissions-row__heading">
      <x-link
        :to="itemLink"
        primary
      >
        {{ item.name }}
      </x-link>

      <x-btn
        ghost
        size="xs"
        color="gray"
        icon="delete-2"
        class="permissions-row__action_delete"
        @click="$emit('delete:item', item)"
      />
    </div>

    <div
      v-if="loading"
      class="permissions-row__loader"
    />

    <div class="permissions-row__content">
      <ul
        v-if="computedItemUserList.length"
        class="permissions-row__users-list"
      >
        <li
          v-for="(user, idx) in computedItemUserList"
          :key="user.id"
          class="permissions-row__user"
        >
          <user-select
            width="320px"
            :value="user"
            :users="computedUserList"
            :clearable="false"
            @input="onUserSelect($event, idx)"
          />

          <delete-button
            @click="onDeleteItemUser(user)"
          />
        </li>
      </ul>

      <div v-else>
        <user-select
          width="320px"
          class="permissions-row__user"
          :users="computedUserList"
          :value="null"
          @input="onUserSelect"
        />
      </div>

      <x-btn
        v-if="unselectedUsers.length"
        ghost
        size="xs"
        prepend="plus"
        @click="onUserAdd"
      >
        Добавить пользователя
      </x-btn>
    </div>
  </div>
</template>

<script>
import UserSelect from '@/components/Settings/Permissions/UserSelect.vue'
import DeleteButton from '@/components/Interface/DeleteButton.vue'

export default {
  components: {
    UserSelect,
    DeleteButton
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    usersList: {
      type: Array,
      default: () => []
    },
    loading: Boolean
  },

  data () {
    return {
      selectedUser: null
    }
  },

  computed: {
    itemLink () {
      return {
        name: 'settings.permissions.client',
        params: { id: this.item.id }
      }
    },

    unselectedUsers () {
      return this.usersList.filter(user => !this.item.users.find(u => u.id === user.id))
    },

    computedUserList () {
      return this.unselectedUsers.map(user => ({ ...user, label: user.name || user.email }))
    },

    computedItemUserList () {
      return this.item.users.map(user => ({ ...user, label: user.name || user.email }))
    }
  },

  methods: {
    onUserSelect (user, idx) {
      const prevUser = this.item.users[idx]
      if (prevUser) {
        this.onDeleteItemUser(prevUser)
      }
      this.$emit('select:user', this.item, user)
    },

    onUserAdd () {
      const [user] = this.unselectedUsers
      this.$emit('select:user', this.item, user)
    },

    onDeleteItemUser (user) {
      this.$emit('delete:user', this.item, user)
    }
  }
}
</script>

<style lang="stylus" scoped>
  loading-image(c1, c2)
    background-image linear-gradient(-45deg, c1 25%, c2 25%, c2 50%, c1 50%, c1 75%, c2 75%, c2)

  .permissions-row
    &__heading
      display flex
      align-items center
      justify-content space-between
      padding $space * 2 ($space * 5)
      background-color: $colors.primary-lightest-2
      border 1px solid $colors.gray-light
      border-radius: $space $space 0 0

    &__content
      padding $space * 4
      border 1px solid $colors.gray-light
      border-top 0
      border-radius: 0 0 $space $space

    &__users-list
      list-style none

    &__user
      display flex
      align-items center
      margin-bottom $space * 3

    &__loader
      width 100%
      height 5px
      background-size 50px 50px
      animation load 2s linear infinite
      pointer-events none
      loading-image($colors.primary, $colors.primary-light)
</style>
