<template>
  <x-loader
    v-if="isLoading"
    class="clients__loader"
  />

  <div
    v-else-if="clientList.length"
    class="clients"
  >
    <div class="clients__action">
      <x-btn
        outline
        prepend="plus"
        class="clients__action_add"
        :to="addClientRoute"
      >
        Добавить Клиента
      </x-btn>
    </div>

    <ul class="clients__list">
      <li
        v-for="client in clientList"
        :key="client.id"
        class="clients__item"
      >
        <permissions-row
          :item="client"
          :users-list="usersList"
          :loading="processedClients.includes(client.id)"
          @delete:item="onDeleteClick"
          @select:user="onUserSelect"
          @delete:user="onUserDelete"
        />
      </li>
    </ul>
  </div>

  <x-placeholder
    v-else
    icon="table-empty"
    desc="Вы не добавили ни одного Клиента"
    class="clients__placeholder"
  >
    <template #bottom>
      <x-btn
        :to="addClientRoute"
      >
        Добавить Клиента
      </x-btn>
    </template>
  </x-placeholder>
</template>

<script>
import PermissionsRow from '@/components/Settings/Permissions/PermissionsRow.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapActions, mapState } = createNamespacedHelpers('settings/permissions')

export default {
  components: {
    PermissionsRow
  },

  data () {
    return {
      isLoading: false,
      processedClients: []
    }
  },

  computed: {
    ...mapState([
      'clientList',
      'usersList'
    ]),

    addClientRoute () {
      return { name: 'settings.permissions.client' }
    }
  },

  created () {
    this.fetchData()
  },

  methods: {
    ...mapActions([
      'getClientList',
      'deleteClient',
      'addClientsUser',
      'removeClientsUser',
      'getUsersList'
    ]),

    async fetchData () {
      await this.fetchClients()
      this.getUsersList()
    },

    async fetchClients () {
      try {
        this.isLoading = true
        await this.getClientList()
      } finally {
        this.isLoading = false
      }
    },

    onDeleteClick (client) {
      this.$modal.show('confirm', {
        title: `Вы уверены что хотите удалить ${client.name}?`,
        onConfirm: () => this.onClientDelete(client)
      })
    },

    async onClientDelete (client) {
      try {
        await this.deleteClient(client.id)
        this.fetchClients()
      } finally {
        this.$modal.hide('confirm')
      }
    },

    async onUserSelect (client, user) {
      try {
        this.processedClients.push(client.id)

        await this.addClientsUser({
          id: client.id,
          user_id: user.id
        })
      } finally {
        const index = this.processedClients.findIndex(id => id === client.id)
        this.processedClients.splice(index, 1)
      }
    },

    async onUserDelete (client, user) {
      try {
        this.processedClients.push(client.id)

        await this.removeClientsUser({
          id: client.id,
          user_id: user.id
        })
      } finally {
        const index = this.processedClients.findIndex(id => id === client.id)
        this.processedClients.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .clients
    &__list
      list-style none

    &__item
      margin-top 20px

    &__action
      display flex

      &_add
        align-self flex-end
        margin-left auto

    &__loader,
    &__placeholder
      margin 100px 0
</style>
