<template>
  <x-loader
    v-if="isLoading"
    class="users__loader"
  />

  <div
    v-else-if="usersList.length"
    class="users"
  >
    <div class="users__action">
      <x-btn
        outline
        prepend="plus"
        class="users__action_add"
        :to="addUserRoute"
      >
        Добавить Пользователя
      </x-btn>
    </div>

    <ul class="users__list">
      <li
        v-for="user in usersList"
        :key="user.id"
        class="users__item"
      >
        <delete-button
          @click="onDeleteClick(user)"
        />

        <x-link
          primary
          class="users__link"
          :to="getUserLink(user)"
        >
          {{ user.name || user.email }}
        </x-link>
      </li>
    </ul>
  </div>

  <x-placeholder
    v-else
    icon="table-empty"
    desc="Вы не добавили ни одного Пользователя"
    class="users__placeholder"
  >
    <template #bottom>
      <x-btn
        :to="addUserRoute"
      >
        Добавить Пользователя
      </x-btn>
    </template>
  </x-placeholder>
</template>

<script>
import DeleteButton from '@/components/Interface/DeleteButton.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapActions, mapState } = createNamespacedHelpers('settings/permissions')

export default {
  components: {
    DeleteButton
  },

  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    ...mapState(['usersList']),

    addUserRoute () {
      return { name: 'settings.permissions.user' }
    }
  },

  created () {
    this.fetchUsers()
  },

  methods: {
    ...mapActions([
      'getUsersList',
      'deleteUser'
    ]),

    async fetchUsers () {
      try {
        this.isLoading = true
        await this.getUsersList()
      } finally {
        this.isLoading = false
      }
    },

    onDeleteClick (user) {
      this.$modal.show('confirm', {
        title: `Вы уверены что хотите удалить ${user.name}?`,
        onConfirm: () => this.onUserDelete(user)
      })
    },

    async onUserDelete (user) {
      try {
        await this.deleteUser(user.id)
        this.fetchUsers()
      } finally {
        this.$modal.hide('confirm')
      }
    },

    getUserLink ({ id }) {
      return {
        ...this.addUserRoute,
        params: { id }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .users
    &__list
      list-style none

    &__item
      display flex
      align-items center
      margin-top 20px

    &__link
      margin-left 10px

    &__action
      display flex

      &_add
        align-self flex-end
        margin-left auto

    &__loader,
    &__placeholder
      margin 100px 0
</style>
