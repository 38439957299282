<template>
  <x-select
    :value="value"
    v-bind="$attrs"
    track-by="id"
    placeholder="Выберите пользователя"
    :options="users"
    :preselect-first="false"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
      default: () => []
    },
    value: {
      type: null,
      default: () => ({})
    }
  }
}
</script>
