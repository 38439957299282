<template>
  <x-loader
    v-if="isLoading"
    class="roles__loader"
  />

  <div
    v-else-if="rolesList.length"
    class="roles"
  >
    <div class="roles__action">
      <x-btn
        outline
        prepend="plus"
        class="roles__action_add"
        :to="addRoleRoute"
      >
        Добавить Роль
      </x-btn>
    </div>

    <ul class="roles__list">
      <li
        v-for="role in rolesList"
        :key="role.id"
        class="roles__item"
      >
        <delete-button
          @click="onDeleteClick(role)"
        />

        <x-link
          primary
          class="users__link"
          :to="getRoleLink(role)"
        >
          {{ role.title }}
        </x-link>
      </li>
    </ul>
  </div>

  <x-placeholder
    v-else
    icon="table-empty"
    desc="Вы не добавили ни одной Роли"
    class="roles__placeholder"
  >
    <template #bottom>
      <x-btn
        :to="addRoleRoute"
      >
        Добавить Роль
      </x-btn>
    </template>
  </x-placeholder>
</template>

<script>
import DeleteButton from '@/components/Interface/DeleteButton.vue'

import { createNamespacedHelpers } from 'vuex'

const { mapActions, mapState } = createNamespacedHelpers('settings/permissions')

export default {
  components: {
    DeleteButton
  },

  data () {
    return {
      isLoading: false,
      processedRoles: []
    }
  },

  computed: {
    ...mapState([
      'rolesList',
      'clientList'
    ]),

    addRoleRoute () {
      return { name: 'settings.permissions.role' }
    }
  },

  created () {
    this.fetchRoles()
  },

  methods: {
    ...mapActions([
      'getRolesList',
      'deleteRole'
    ]),

    async fetchRoles () {
      try {
        this.isLoading = true
        await this.getRolesList()
      } finally {
        this.isLoading = false
      }
    },

    onDeleteClick (role) {
      this.$modal.show('confirm', {
        title: `Вы уверены что хотите удалить «${role.title}»?`,
        onConfirm: () => this.onRoleDelete(role)
      })
    },

    async onRoleDelete (role) {
      try {
        await this.deleteRole(role.id)
        this.fetchRoles()
      } finally {
        this.$modal.hide('confirm')
      }
    },

    getRoleLink (role) {
      return {
        ...this.addRoleRoute,
        params: { id: role.id }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .roles
    &__list
      list-style none

    &__item
      display flex
      align-items center
      margin-top 20px

    &__link
      margin-left 10px

    &__action
      display flex

      &_add
        display flex
        align-self flex-end
        margin-left auto

    &__loader,
    &__placeholder
      margin 100px 0
</style>
