<template>
  <x-btn
    ghost
    size="xs"
    color="gray"
    :icon="icon"
    class="delete-btn"
    @click="$emit('click')"
  >
    <slot />
  </x-btn>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'x-circle'
    }
  }
}
</script>

<style lang="stylus" scoped>
  .delete-btn
    >>> .x-btn__icon
      color $colors.gray

    &:hover
      background-color transparent
</style>
