<template>
  <div class="permissions-manage">
    <x-tabs
      size="l"
      :tabs="permissionTabs"
      :selected="selectedTabIdx"
      class="permissions-manage__tabs"
      @change="setSelectedTab"
    />

    <transition name="tabs-fade">
      <component
        :is="currentTabComponent"
      />
    </transition>
  </div>
</template>

<script>
import * as TabViews from '@/components/Settings/Permissions/Tabs'
import { permissionTabs } from '@/constants/settings'
import '@/styles/transitions.styl'

export default {
  components: {
    ...TabViews
  },

  data () {
    return {
      permissionTabs,
      selectedTabIdx: 0
    }
  },

  computed: {
    currentTabComponent () {
      return this.permissionTabs[this.selectedTabIdx].id
    }
  },

  methods: {
    setSelectedTab (tabIdx) {
      this.selectedTabIdx = tabIdx
    }
  }
}
</script>

<style lang="stylus" scoped>
  .permissions-manage
    &__tabs
      margin-bottom 20px
</style>
